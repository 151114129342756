type TranslationKeys = keyof typeof languageHelper.en_US;
type TranslationMap = Partial<typeof languageHelper.en_US>;

// @ts-ignore
import cacheFile from "~svelte/static/locales/cache.js";

export type LanguageHelperModule = {
  languages: typeof import("../../static/locales/languages.json");
  localized_languages: typeof import("../../static/locales/localized_languages.json");
  en_US: typeof import("../../static/locales/client.en_US.json");
  currentLocale?: string;
  load: (key: string | string[]) => Promise<any>;
  loadAll: () => Promise<any>;
  use: (locale: string) => Promise<void>;
  $translate: (translationKeys: TranslationKeys[]) => Promise<TranslationMap>;
} & Partial<typeof import("../../static/locales/locales.merged.json")>;

declare global {
  interface Window {
    languageHelper: LanguageHelperModule;
  }
  const languageHelper: LanguageHelperModule;
}

async function $translate(translationKeys: TranslationKeys[]): Promise<TranslationMap> {
  const language = await languageHelper.load(languageHelper.currentLocale);
  const translation = {} as TranslationMap;
  translationKeys.forEach(key => {
    translation[key] = language[key];
  });
  return translation;
}

function setLocale(locale: string) {
  languageHelper.currentLocale = locale;
  RjEvent.emit("locale-changed", locale);
}

async function use(locale: string) {
  await languageHelper.load(locale);
  setLocale(locale);
}

async function loadAll() {
  const locales = await Promise.all(Object.keys(languageHelper.languages).map(key => languageHelper.load(key)));
  return locales;
}

async function load(key: string | string[]) {
  if (Array.isArray(key)) {
    return await Promise.all(key.map(k => languageHelper.load(k)));
  } else if (typeof key === "string" && !languageHelper.hasOwnProperty(key)) {
    const response = await fetch(`/static/locales/client.${key}.json?cache=${cacheFile.cache}`);
    if (!response.ok) return;
    const data = await response.json();
    languageHelper[key] = data;
    //  @ts-ignore
    const angular = window.angular;
    if (angular) {
      const $timeout = angular.element(document).injector().get("$timeout");
      $timeout(() => {
        angular.element(document).scope().$apply();
      });
    }
    return data;
  }
  return languageHelper[key];
}

const createLanguageHelperProxy = (obj: any = {}) => {
  return new Proxy(obj, {
    get: function (locales, key) {
      if (!(key in locales) && locales.languages && locales.languages[key]) {
        const lang = key as string;
        locales[lang] = {};
        fetch(`/static/locales/client.${lang}.json?cache=${cacheFile.cache}`).then(response => {
          if (response.ok) {
            response.json().then(data => {
              Object.assign(locales[lang], data);
              //  @ts-ignore
              const angular = window.angular;
              if (angular) {
                const $timeout = angular.element(document).injector().get("$timeout");
                $timeout(() => {
                  angular.element(document).scope().$apply();
                });
              }
            });
          }
        });
        return locales[lang];
      }
      return locales[key];
    },
  });
};

export async function createLanguageHelper(): Promise<LanguageHelperModule> {
  const hasWindow = !!window.languageHelper;
  if (hasWindow) return window.languageHelper;
  if (!hasWindow) {
    window.languageHelper = createLanguageHelperProxy();
    const responses = await Promise.all([
      fetch(`/static/locales/languages.json?cache=${cacheFile.cache}`),
      fetch(`/static/locales/localized_languages.json?cache=${cacheFile.cache}`),
      fetch(`/static/locales/client.en_US.json?cache=${cacheFile.cache}`),
    ]);
    const [languages, localized_languages, en_US] = await Promise.all([
      responses[0].json(),
      responses[1].json(),
      responses[2].json(),
    ]);

    window.languageHelper.en_US = en_US;
    window.languageHelper.languages = languages;
    window.languageHelper.localized_languages = localized_languages;
    // collection and recruiting uses the english-only client.en_US.json file in case we translate the whole site... ??
    if (window.location.pathname.match(/^\/(recruiting|collection)/)) {
      const client_en_US = await (
        await fetch(`/static/locales/english_only.en_US.json?cache=${cacheFile.cache}`)
      ).json();
      Object.assign(window.languageHelper.en_US, client_en_US);
    }
    window.languageHelper.load = load;
    window.languageHelper.loadAll = loadAll;
    window.languageHelper.use = use;
    window.languageHelper.$translate = $translate;
  }
  return window.languageHelper;
}

export default createLanguageHelper;
