import * as angular from "angular";

export function byString(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  while (a.length) {
    var n = a.shift();
    if (n in o) {
      o = o[n];
    } else {
      return;
    }
  }
  return o;
}

export function getRef(obj: any, str: string) {
  const split = str.split(".");
  let i = 0;

  while (i < split.length && obj) {
    obj = obj[split[i]];
    i++;
  }
  return obj;
}

export function getInjector<T = any>(name: string): T {
  return angular.element(document).injector().get(name) as T;
}

export function escapeSpecialChars(str: string): string {
  return str.replace(/"/g, "'");
}

export function guid() {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
}

window.guid = guid;
window.getRef = getRef;
window.getInjector = getInjector;
window.escapeSpecialChars = escapeSpecialChars;
Object.byString = byString;
