export const TextFormatNames = [
  "Date (mm/dd/yyyy)",
  "Date (dd/mm/yyyy)",
  "Email",
  "Phone Number (US)",
  "Zip Code (US)",
  "Custom",
  "Currency (USD)",
  "Phone Number (international)",
  "Time (24 hour)",
  "Time (12 hour)",
  "URL",
] as const;
export type TextFormatName = (typeof TextFormatNames)[number];
export interface TextFormatterPlainJSON {
  name: TextFormatName;
  regexPattern: string;
  invalidMessage: string;
  examples: string[];
}

type TextFormatterClass = Omit<TextFormatterPlainJSON, "regexPattern"> & {
  regex: RegExp;
  multiLangInvalidMessage?: Record<string, string>;
  multiLangName?: Record<string, string>;
};

// We want this, except for 'regexPattern', which becomes 'regex'
class TextFormatter implements TextFormatterClass {
  name: TextFormatName;
  regex: RegExp;
  invalidMessage: string;
  examples: string[] = [];
  multiLangName: Record<string, string> = {};
  multiLangInvalidMessage: Record<string, string> = {};

  constructor(config: Omit<TextFormatterClass, "regex"> & { regex: RegExp | string }) {
    this.createFormatter(config);
  }

  static fromRegexPattern(config: Omit<TextFormatterPlainJSON & TextFormatterClass, "regex">) {
    return new TextFormatter({
      name: config.name,
      regex: config.regexPattern,
      invalidMessage: config.invalidMessage,
      examples: config.examples,
      multiLangName: config.multiLangName,
      multiLangInvalidMessage: config.multiLangInvalidMessage,
    });
  }

  getInvalidMessage(lang: string) {
    const errorMessage = this.multiLangInvalidMessage[lang];
    const defaultMessage = languageHelper[lang][`TEXT_FORMAT_INVALID_MESSAGES.${this.name}`];
    return errorMessage || defaultMessage || "Invalid";
  }

  createFormatter(config: Omit<TextFormatterClass, "regex"> & { regex: RegExp | string }) {
    this.name = config.name;
    if (typeof config.regex === "string") {
      this.regex = TextFormatter.convertStringToRegex(config.regex);
    } else if (config.regex instanceof RegExp) {
      this.regex = config.regex;
    }
    this.invalidMessage = config.invalidMessage;
    this.examples = config.examples;
    this.multiLangName = config.multiLangName || {};
    this.multiLangInvalidMessage = config.multiLangInvalidMessage || {};
  }

  isValid(input: string): boolean {
    return this.regex.test(input);
  }

  date(input: string | Date): Date {
    if (!input) return;
    if (typeof input === "string") {
      if (this.name == "Date (mm/dd/yyyy)") {
        return new Date(input);
      }
      if (this.name == "Date (dd/mm/yyyy)") {
        const dateParts = input.split("/");
        return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
      }
      if (this.name == "Time (12 hour)" || this.name == "Time (24 hour)") {
        return new Date(`01/01/1970 ${input}`);
      }
    } else if (Date.prototype.isPrototypeOf(input)) {
      return input;
    }
  }

  format(input: string | Date): string {
    // proceed as normal
    if (typeof input === "string") {
      return input;
      // if it's a date from the calendar, it will be a Date object. Turn it into the format we want.
    } else if (typeof input == "object") {
      const date = new Date(input as Date);
      // if date format is mm/dd/yyyy:
      if (this.name == "Date (mm/dd/yyyy)") return date.toLocaleDateString("en-US");
      // if date format is dd/mm/yyyy:
      if (this.name == "Date (dd/mm/yyyy)") return date.toLocaleDateString("en-GB");
      // if date format is Time (24 hour)
      if (this.name == "Time (24 hour)")
        return date.toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" });
      // if date format is Time (12 hour)
      if (this.name == "Time (12 hour)")
        return date.toLocaleTimeString("en-US", { hour12: true, hour: "2-digit", minute: "2-digit" });
    }
    return "";
  }

  static convertRegexToString(regex: RegExp | string) {
    if (typeof regex === "string") {
      return regex;
    }
    return regex.toString();
  }

  static convertStringToRegex(regStr: string) {
    // add "/" characters to beginning and end of string if they are missing
    if (regStr[0] !== "/") {
      regStr = "/" + regStr;
    }
    if (regStr[regStr.length - 1] !== "/") {
      regStr = regStr + "/";
    }
    // remove the first and last "/" characters
    const regexString = regStr.slice(1, -1);
    return new RegExp(regexString);
  }
}

export default TextFormatter;
