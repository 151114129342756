import createLanguageHelper from "~svelte/lib/language-helper/language-helper";

// At this point, there is HTML content that has been loaded, but AngularJS has not yet been initialized.
const Initializers = {
  // puts language helper on the window object
  async languageHelper(angular?: any) {
    const languageHelper = await createLanguageHelper();
    if (angular) {
      angular.module("redjade.shared").value("languageHelper", languageHelper);
    }
    return languageHelper;
  },
};

export default Initializers;
