import TextFormatter from "./TextFormatter"

const textFormatExamples: TextFormatter[] = [
    // EMAIL
    new TextFormatter({
        name: "Email", 
        regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        invalidMessage: "Please enter a valid email address", 
        examples: [
            "validaddress@mail.com"
        ],
        multiLangName: {
            "en_US": "Email",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid email address",
        }   
    }),
    // Date (mm/dd/yyyy)
    new TextFormatter({
        name: "Date (mm/dd/yyyy)",
        regex: /^(0?[1-9]|1[012])[-\/.](0?[1-9]|[12][0-9]|3[01])[-\/.](19|20)\d\d$/,
        invalidMessage: "Please enter a valid date",
        examples: [
            "12/31/2000",
            "01/1/2000",
            "1/01/2000",
            "01-01-2000",
        ],
        multiLangName: {
            "en_US": "Date (mm/dd/yyyy)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid date",
        }
    }),
    // Date (dd/mm/yyyy)
    new TextFormatter({
        name: "Date (dd/mm/yyyy)",
        regex: /^(0?[1-9]|[12][0-9]|3[01])[-\/.](0?[1-9]|1[012])[-\/.](19|20)\d\d$/,
        invalidMessage: "Please enter a valid date",
        examples: [
            "31/12/2000",
            "1/1/2000",
            "31/1/2000",
            "1/12/2000",
        ],
        multiLangName: {
            "en_US": "Date (dd/mm/yyyy)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid date",
        }
    }),

    // CURRENCY (USD)
    new TextFormatter({
        name: "Currency (USD)",
        regex: /^\$?\d{1,3}(?:,?\d{3})*(?:\.\d{2})?$/,
        invalidMessage: "Please enter a valid currency amount",
        examples: [
            "$1,000.00",
            "$1,000",
            "1,000.00",
            "1000",
            "$1000.00"
        ],
        multiLangName: {
            "en_US": "Currency (USD)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid currency amount",
        } 
    }),
    // PHONE (US)
    new TextFormatter({
        name: "Phone Number (US)",
        regex: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
        invalidMessage: "Please enter a valid phone number",
        examples: [
            "123-456-7890", 
            "(123) 456-7890", 
            "123 456 7890", 
            "123.456.7890", 
        ],
        multiLangName: {
            "en_US": "Phone Number (US)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid phone number",
        } 
    }),
    // PHONE (INTERNATIONAL)
    new TextFormatter({
        name: "Phone Number (international)",
        regex: /^(?:\+?\d{1,3}\s?)?(?:\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/,
        invalidMessage: "Please enter a valid phone number",
        examples: [
            "+1 1234567890",
            "123-456-7890",
            "1-800-555-1234",
            "(555) 123-4567",
            "123.456.7890",
            "+44 20 1234 5678",
            "123456789012345"
        ],
        multiLangName: {
            "en_US": "Phone Number (international)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid phone number",
        } 
    }),

    // ZIP CODE (US)
    new TextFormatter({
        name: "Zip Code (US)",
        regex: /^\d{5}(?:[-\s]\d{4})?$/,
        invalidMessage: "Please enter a valid zip code",
        examples: [
            "12345", 
            "12345-6789"
        ],
        multiLangName: {
            "en_US": "Zip Code (US)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid zip code",
        } 
    }),

    // TIME (24 HOUR)
    new TextFormatter({
        name: "Time (24 hour)",
        regex: /^(?:[01]\d|2[0123]):(?:[012345]\d)$/,
        invalidMessage: "Please enter a valid time",
        examples: [
            "04:00",
            "13:30",
            "19:25",
        ],
        multiLangName: {
            "en_US": "Time (24 hour)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid time",
        }
    }),

    // TIME (12 HOUR)
    new TextFormatter({
        name: "Time (12 hour)",
        regex: /^(?:1[012]|0?[1-9]):(?:[012345]\d)(?:\s?(([ap]m)|([AP]M)))$/,
        invalidMessage: "Please enter a valid time",
        examples: [
            "12:00 am",
            "11:45 PM",
            "3:30 am",
            "03:30 AM"
        ],
        multiLangName: {
            "en_US": "Time (24 hour)",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid time",
        }
    }),
    
    // URL
    new TextFormatter({
        name: "URL",
        regex: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        invalidMessage: "Please enter a valid URL",
        examples: [
            "https://www.google.com",
        ],
        multiLangName: {
            "en_US": "URL",
        },
        multiLangInvalidMessage: {
            "en_US": "Please enter a valid URL",
        } 
    }),
]

export default textFormatExamples;