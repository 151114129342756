import urls from "~angular/src/rj_shared/utils/urls"
import cookies from "~angular/src/rj_shared/utils/cookies"

const STORAGE_SETTING_NAME = "token_local_storage"
const TRACKED_TOKENS = [
  "survey_participant",
  "station_identity_token",
  "station_identity_token_offsite",
  "online_redirect",
]

const isBlank = function (value: any) {
  return [null, undefined, "null", "undefined", ""].includes(typeof value === "string" ? value.trim() : value)
}

// openAI says more reliable than navigator.cookieEnabled because it can be spoofed
const checkCookiesEnabled = function () {
  document.cookie = "__verify=1"
  const success = document.cookie.length >= 1 && document.cookie.indexOf("__verify=1") !== -1
  const thePast = new Date(1976, 8, 16)
  document.cookie = "__verify=1;expires=" + thePast.toUTCString()
  return success
}

const checkLocalStorageEnabled = function () {
  try {
    const storage = window.localStorage
    const x = "__verify"
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

const isStorageMethodLocal = function () {
  // NOTE: default false if no setting since using the new feature requires true
  if (
    checkLocalStorageEnabled() &&
    (window.localStorage.getItem(STORAGE_SETTING_NAME) === "true" || !checkCookiesEnabled())
  ) {
    // if cookies disabled force localStorage
    return true
  }
  return false
}

// if we are updating the setting refresh the token locations by fetching
const refreshValues = function () {
  TRACKED_TOKENS.forEach(tokenName => {
    get(tokenName)
  })
}

const setUseLocalStorage = function (useLocal: any) {
  let res
  if (useLocal) {
    res = window.localStorage.setItem(STORAGE_SETTING_NAME, useLocal)
  } else {
    res = window.localStorage.removeItem(STORAGE_SETTING_NAME)
  }

  refreshValues()
  return res
}

const set = function (tokenName: any, tokenValue: any, options: any) {
  if (isStorageMethodLocal() && checkLocalStorageEnabled()) {
    if (isBlank(tokenValue)) {
      remove(tokenName)
    } else {
      window.localStorage.setItem(tokenName, tokenValue)
      navigator?.serviceWorker?.controller?.postMessage({ tokenName, tokenValue })
    }
  }

  if (!isStorageMethodLocal() && !isBlank(tokenValue)) {
    // options is only needed for cookies
    if (options == null) {
      options = undefined
    }

    cookies.setCookie(tokenName, tokenValue, options)
  }

  return tokenValue
}

const get = function (tokenName: any) {
  let value = null
  const local = window.localStorage.getItem(tokenName)
  const cookie = cookies.getCookie(tokenName)
  if (isStorageMethodLocal()) {
    value = local || cookie
  } else {
    value = cookie || local
  }
  // if changing between settings we do not want stations to have to be re-created or participants to get kicked out of surveys

  if (isBlank(value)) {
    return undefined
  } else {
    // we have the value in memory so call remove so that it removes from old storage location
    // in case we are changing settings and persisting a token OR manually clearing a token
    // remove(tokenName)
    // return set(tokenName, value, null)
    return value
  }
}

const remove = function (tokenName: string) {
  window.localStorage.removeItem(tokenName)

  if (!isBlank(cookies.getCookie(tokenName))) {
    // we are only doing surveys, so hard coding the endpoint into the remove
    // and avoiding utils/cookies.deleteCookie which doesnt add domain or path
    return (document.cookie =
      tokenName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=" + "." + process.env.BASE_URL)
  }
}

const setSurveyParticipant = function (tokenValue) {
  set("survey_participant", tokenValue, {
    secure: urls.is_secure(),
    domain: urls.cookieDomainUrl(),
  })
}
const setStationIdentityToken = function (tokenValue) {
  let expiration_date = new Date()
  expiration_date.setFullYear(expiration_date.getFullYear() + 10)

  set("station_identity_token", tokenValue, {
    expires: expiration_date,
    secure: urls.is_secure(),
    domain: urls.cookieDomainUrl(),
  })
}

const setStationIdentityTokenOffsite = function (tokenValue) {
  let expiration_date = new Date()
  expiration_date.setFullYear(expiration_date.getFullYear() + 10)
  set("station_identity_token_offsite", tokenValue, {
    expires: expiration_date,
    secure: urls.is_secure(),
    domain: urls.cookieDomainUrl(),
  })
}

const setOnlineRedirect = function (tokenValue) {
  set("online_redirect", tokenValue, {
    session: true,
    secure: urls.is_secure(),
    domain: urls.cookieDomainUrl(),
  })
}

const tokenManager = {
  setUseLocalStorage,
  get,
  remove,
  setSurveyParticipant,
  setStationIdentityToken,
  setOnlineRedirect,
  setStationIdentityTokenOffsite,
}

export default tokenManager
